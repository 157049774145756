/**
 * Copyright ©2024 Drivepoint
 */

import dayjs from "dayjs";
import {useModal} from "mui-modal-provider";
import {useNavigate} from "react-router-dom";
import React, {forwardRef, JSX, useEffect, useMemo, useRef} from "react";
import {
  Avatar, Box, Chip, CircularProgress, DialogProps, Divider, Grid, Icon, ListItem, ListItemIcon,
  ListItemText, MenuList, Typography
} from "@mui/material";
import {Config, useStateChange, useStore} from "@bainbridge-growth/node-frontend";
import DrivepointUser from "../../services/DrivepointUser.ts";
import FeatureFlagsService from "../../services/FeatureFlagService.ts";
import Firebase from "../../services/Firebase.ts";
import Telemetry from "../../services/telemetry/Telemetry.ts";
import WebAppServerClient from "../../services/WebAppServerClient.ts";
import FilesStore from "../../stores/FilesStore.ts";
import PlanStore from "../../stores/PlanStore.ts";
import {PlanProps} from "../../types/ExcelModel.ts";
import ExcelUtilities from "../../utilities/ExcelUtilities.ts";
import DPButton from "../DPButton/DPButton.tsx";
import DPDialog from "../DPDialog/DPDialog.tsx";
import DPDrawer, {DPDrawerInterface} from "../DPDrawer/DPDrawer.tsx";
import DPDataFreshness from "../DPInfoComponents/DPDataFreshness/DPDataFreshness.tsx";
import DPPageLoadingVeil, {DPPageLoadingVeilInterface} from "../DPPageLoadingVeil/DPPageLoadingVeil.tsx";
import DPPrimaryLogo from "../../../assets/drivepoint-logo-primary.png";
import "./DPNavigationMenu.css";

export type NavigationMenuProps = {
};

function  NavigationMenu(props: NavigationMenuProps, ref: any): JSX.Element {

  const loader = useRef<DPPageLoadingVeilInterface>();
  const [plan] = useStore<PlanProps, PlanStore>(PlanStore);
  const [files] = useStore<Record<string, any>[], FilesStore>(FilesStore);

  const navigate = useNavigate();
  const {showModal} = useModal();

  const dpUser = useStateChange<DrivepointUser>("user");
  const company =  useStateChange<any>("company");
  const data_import_info = useStateChange<any[]>("data_imports_info");
  const flags = useStateChange<any>("flags");

  const planStatus = useMemo(() => {
    if (!plan) { return; }
    const currentPlan = files.find(el => el.id === plan.id) ?? {};
    if (!currentPlan) { return; }
    const isDirty = dayjs(currentPlan?.updated_at).isAfter(dayjs(currentPlan.last_dwh_sync_timesteamp));
    if (["ready", "dwh_saving_failed"].includes(currentPlan?.state) && isDirty) { return "unsync"; }
    if (["dwh_saving", "dwh_sync_pending"].includes(currentPlan?.state)) { return "syncing"; }
    return "sync";
  }, [files, plan]);

  const isImportDataInProgress = useMemo(() => data_import_info?.some(el => el.import_status === "progress") ?? false, [data_import_info]);

  const menuItems = useMemo(() => [
    {id: "home", name: "Home", Icon: "home", click: () => { navigate("/HomePage"); }},
    {id: "sync", name: "Sync to Drivepoint", Icon: "refresh", click: handleSync, needPlan: true},
    {id: "import_data", name: "Import Data", Icon: "downloading", click: () => { navigate("/ImportDataPage"); }, needPlan: true},
    (dpUser?.excelUser && dpUser?.excelUser?.role === "superAdmin") ?
      {id: "update_actuals", name: "Update Actuals", Icon: "attach_money", click: handleUpdateActuals, needPlan: true}
      : null,
    {id: "roll_forward", name: "Roll Forward", Icon: "drive_file_move", click: () => { navigate("/RollForwardPage"); }, needPlan: true},
    {id: "save_plan", name: "Save as New Plan", Icon: "file_copy", click: () => { navigate("/NewPlanPage"); }, needPlan: true},
    {id: "import_plans", name: "Import Plans", Icon: "multiline_chart", click: () => { navigate("/VarianceReviewPage"); }, needPlan: true},
    {id: "browse_plans", name: "Browse other Plans", Icon: "open_in_new", click: () => { window.open(`${Config.get("webapp.url")}/${company?.id ? `${company?.id}/plans` : ""}`, "_blank"); }, needPlan: true},
    {id: "help", name: "Help Center", Icon: "help", click: () => { window.open("https://docs.drivepoint.io/help", "_blank"); }}
  ].filter(it => it), [company?.id, plan?.id]);

  useEffect(() => {
    (planStatus === "syncing") ? ExcelUtilities.toggleButtonState("Sync", false) : ExcelUtilities.toggleButtonState("Sync");
  }, [planStatus]);

  function handleSpinner(itemName: string) {
    let showSpinner = false;
    if (planStatus === "syncing" && itemName.toLowerCase() === "sync to drivepoint") { showSpinner = true; }
    if (itemName.toLowerCase() === "import data" && isImportDataInProgress) { showSpinner = true; }
    return showSpinner ? <CircularProgress size={20} /> : <></>;
  }

  function handleDisableMenuItem(itemName: string) {
    if (plan.id === "not_smartmodel" && !["Help Center", "Browse other Plans", "Home"].includes(itemName)) { return true; }
    return planStatus === "syncing" && itemName.toLowerCase() === "sync to drivepoint";
  }

  async function handleSync() {
    await WebAppServerClient.request(`/api/company/${company.id}/plan/${plan.id}/sync`, "PATCH");
  }

  function handleUpdateActuals() {
    showModal(DPDialog, {
      children: renderUpdateActualsDialogBody(),
      title: "Update Actuals",
      hideHeaderDivider: true,
      actions: (props) => renderUpdateActualsDialogActions(props)
    });
  }

  function renderCompatibilityIssueBadge() {
    return <Chip
      label="Compatibility Issue" variant="outlined"
      icon={<Icon color="warning" sx={{fontSize: "10px", marginLeft: "10px!important"}} >circle</Icon>}
    />;
  }

  function renderMenuItem(item: any, index: number): any {
    return <Box key={item.name}>
      <ListItem
        className="dp-nav-item"
        sx={{color: handleDisableMenuItem(item.name) ? "var(--GREY-300)" : "var(--GREY-600)"}}
        onClick={() => {
          if (!handleDisableMenuItem(item.name)) {
            Telemetry.track("click_drawer_menu", {menu_name: item.name});
            ref.current.toggle();
            item.click();
          }
        }}>
        <ListItemIcon sx={{color: handleDisableMenuItem(item.name) ? "var(--GREY-300)" : "var(--GREY-600)"}}><Icon>{item.Icon}</Icon></ListItemIcon>
        <ListItemText>{item.name}</ListItemText>
        <Typography variant="body2">{handleSpinner(item.name)}</Typography>
      </ListItem>
      {index !== (menuItems.length - 1) && <Divider/>}
    </Box>;
  }

  function canUserSeeMenuItem(item: any): boolean {
    return flags?.[`excel_add_in.${item.id}.menu_item.enabled`] ?? true;
  }

  function renderMenuItems(): any {
    const isPlan = plan?.id && plan.id !== "not_smartmodel";
    return menuItems
      .filter(canUserSeeMenuItem)
      .filter(item => item.needPlan ? isPlan : true)
      .map(renderMenuItem);
  }

  function renderMenu() {
    return <MenuList>{renderMenuItems()}</MenuList>;
  }

  function renderFooterMenu() {
    return <MenuList>
      <ListItem className="dp-nav-item" onClick={async () => {
        ref.current.toggle();
        Firebase.auth.signOut();
        await Telemetry.track("sign_out");
      }}>
        <ListItemIcon><Icon>logout</Icon></ListItemIcon>
        <ListItemText>Sign Out</ListItemText>
      </ListItem>
    </MenuList>;
  }

  function renderTopText() {
    if (!dpUser?.excelUser || !company) { return <></>; }
    return <>
      <Typography variant="h6">Hello {dpUser.excelUser.firstName} {dpUser.excelUser.lastName}!</Typography>
      <Typography variant="body2">{company?.name}</Typography>
    </>;
  }

  function renderAvatar() {
    if (!dpUser?.excelUser || !company) { return <></>; }
    return <Avatar src={dpUser?.user?.photoURL}>
      {dpUser?.excelUser?.firstName[0]}{dpUser?.excelUser?.lastName[0]}
    </Avatar>;
  }

  function renderUpdateActualsDialogBody() {
    return <Grid container>
      <Grid item xs={12} mb={2}>
        <Typography variant="body2">
          This action will copy actuals from the <b>R-GL schedule</b> in this current model and send it to the Drivepoint Data Warehouse.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" style={{color: "var(--GREY-400)"}}>
          We typically use the actuals from the Live Model,
          but you can override it by using the actuals in this model by clicking 'Proceed'.
        </Typography>
      </Grid>
    </Grid>;
  }

  function renderUpdateActualsDialogActions(props: Pick<DialogProps, "onClose">) {
    return <Grid container justifyContent="flex-end">
      <Grid item>
        <DPButton label="Cancel" variant="text" onClick={() => { props.onClose({}, "backdropClick"); }} />
      </Grid>
      <Grid item>
        <DPButton label="Proceed" variant="contained" onClick={async () => {
          props.onClose({}, "backdropClick");
          try {
            loader.current.show("Updating Actuals...");
            // TODO: THIS IS DUPLICATE CODE, CLEAN IT UP! both invokers for ImportFinancials should do the same thing.. and RollForward and UpdateFinancials should do it all BUT not replaceGeneralLedger()
            // TODO: Dana ADD a marker for the backend to set the async job know when the sharepoint sheet actually gets updated
            await ExcelUtilities.recalculateTab("M - Monthly");
            await WebAppServerClient.request(`/api/company/${company.id}/plan/${plan.id}/saveFinancials`, "PATCH", {updateActuals: true});
            loader.current.hide();
          } catch (error: any) {
            loader.current.hide();
            logger.error(error.message);
          }
        }} />
      </Grid>
    </Grid>;
  }

  return <>
    <DPPageLoadingVeil ref={loader} />
    <DPDrawer
      PaperProps={{style: {maxWidth: "332px", width: "80%"}}}
      ref={ref}>
      <Grid container className="navigation-menu">
        <Grid item xs={12}>
          <Grid item xs={12} sx={{p: 2, pb: "16px"}}><img alt="logo" src={DPPrimaryLogo} height="35px" /></Grid>
          <Grid item xs={12} pl={2} pb={1}>{renderAvatar()}</Grid>
          <Grid item xs={12} pl={2}>{renderTopText()}</Grid>
          {plan.id === "not_smartmodel" && <Grid item xs={12} pl={2}>{renderCompatibilityIssueBadge()}</Grid>}
          {plan.id !== "not_smartmodel" && <Grid item xs={12} pl={2} pt={1} pr={1}><DPDataFreshness/></Grid>}
          <Grid item xs={12} p={2} pt={0}>{renderMenu()}</Grid>
        </Grid>
        <Grid item xs={12} display="flex" alignItems="flex-end" p={2}>
          <Grid container>
            <Grid item xs={12}>
              {renderFooterMenu()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DPDrawer>
  </>;

}

const DPNavigationMenu = forwardRef<DPDrawerInterface, NavigationMenuProps>(NavigationMenu);

export default DPNavigationMenu;
